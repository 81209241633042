import React from "react";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import PreviewCompatibleImage from "@components/PreviewCompatibleImage";
import { formatIntl } from "@utils/intl";

import "./index.sass";

const HelpCategoryBullet = ({ className, link, icon_image, intl_title, is_preview }) => {
    const intl = useIntl();
    const name = formatIntl(intl, intl_title);
    let component = (
                <div className="help-category-bullet-wrapper">
                    <div className="help-category-bullet-img">
                        {icon_image && <PreviewCompatibleImage imageInfo={{
                            image: icon_image,
                            alt: name
                        }}/>}
                    </div>
                    <span className="help-category-bullet-text">{name}</span>
                </div>
    );

    if (!is_preview) {
        component = (
            <Link to={link}>
                {component}
            </Link>
        );
    }
    return (
        <div className={`help-category-bullet${className? " " + className : ""}`}>
            {component}
        </div>
    );
};

export default HelpCategoryBullet;