import { sanitizeObject } from "@utils/object";

export const postToHelp = (post) => {
    const sanitized = sanitizeObject(post.frontmatter)
    return {
        ...sanitized,
        slug: post.fields.slug
    };
};

export const postToHelpCategory = (post) => {
    const sanitized = sanitizeObject(post.frontmatter)
    return {
        ...sanitized,
        slug: post.fields.slug
    };
};