import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";

import Layout from "@components/Layout";
import SearchBar from "@components/SearchBar";
import HelpCategoryBullet from "@components/HelpCategoryBullet";
import MarkdownIntl from "@components/MarkdownIntl";
import Accordion from "@components/Accordion";

import { formatIntl } from "@utils/intl"
import { postToHelp, postToHelpCategory } from "@utils/help"
import "./index.sass";

const generateAccordionItems = (intl, helps) => {
    return helps.map(({question, answer}) => ({
        title: formatIntl(intl, question),
        value: formatIntl(intl, question),
        children: (
            <span className="help-index-page-bottom-content-question-body">
                <MarkdownIntl message={formatIntl(intl, answer)}/>
            </span>
        )
    }));
}

export const HelpIndexPageTemplate = ( { helpCategories, helps }) => {
    const intl = useIntl()
    return (
        <div className="help-index-page">
            <div className="help-index-page-top-content">
                <div className="help-index-page-top-content-bg" />
                <div className="help-index-page-top-content-wrapper">
                    <h2 className="help-index-page-top-content-title">
                        <FormattedMessage id="HelpIndexPage_TITLE" />
                    </h2>
                    <div className="help-index-page-top-content-searchbar">
                        <SearchBar />
                    </div>
                    <div className="help-index-page-top-content-category">
                        {helpCategories.map((helpCategory, index) => (
                            <HelpCategoryBullet key={`${index}-${helpCategory.slug}`} className="help-index-page-top-content-category-item" {...helpCategory} />
                        ))}
                    </div>
                </div>
            </div>
            {helps && helps.length > 0 &&
                <div className="help-index-page-bottom-content">
                    <h2 className="help-index-page-bottom-title">
                        <FormattedMessage id="HelpIndexPage_BOTTOM_CONTENT_TITLE"/>
                    </h2>
                    <div className="help-index-page-bottom-content-wrapper">
                        <Accordion items={generateAccordionItems(intl, helps)} />
                    </div>
                </div>
            }
        </div>
    );
}

const HelpIndexPage = ({ data }) => {
    const { allMarkdownRemark } = data;
    const helpCategories = allMarkdownRemark.nodes
        .filter(node => node.frontmatter.templateKey === "HelpCategoryPage").map(postToHelpCategory);
    const helps = allMarkdownRemark.nodes
        .filter(node => node.frontmatter.templateKey === "HelpPage").map(postToHelp);
    return (
        <Layout isHeaderFixed>
            <HelpIndexPageTemplate helpCategories={helpCategories} helps={helps} />
        </Layout>
    );
}

export const helpIndexPageQuery = graphql`
    query HelpIndexPage {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    templateKey: {
                        in: ["HelpPage", "HelpCategoryPage"]
                    }
                    is_featured: {
                        eq: true
                    }
                    IS_DEFAULT: {
                        ne: true
                    }
                    IS_HIDDEN: {
                        ne: true
                    }
                }
            }
        ) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    templateKey
                    question {
                        en
                        id
                    }
                    icon_image {
                        childImageSharp {
                            gatsbyImageData(quality: 20, layout: CONSTRAINED, placeholder: BLURRED)
                        }
                    }
                    answer {
                        en
                        id
                    }
                    intl_title {
                        en
                        id
                    }
                }
            }
        }
    }
`

export default HelpIndexPage;